<template>

	<div class="container" v-if="afisareChestionare">
		<el-card class="box-card card">
			<p v-if="eroriChestionar.length">
				<el-alert :title="eroriChestionar.length == 1 ? $t('account.chestionare.error-card-title-sg') : $t('account.chestionare.error-card-title-pl')" type="error" :description="generareTextEroriChestionar()" show-icon></el-alert>
			</p>
			<el-tooltip class="item" effect="dark" :content="$t('account.chestionare.hide-tooltip').replace('%days%',ascundePentruZile)" placement="left">
				<el-button type="warning" class="ascundeChestionare" @click="funcAscundePentruZile()" icon="el-icon-date" plain circle></el-button>
			</el-tooltip>
			<h3>
				<strong>
					{{$t('account.chestionare.card-title')}}
				</strong>
			</h3>
			<p>
				{{$t('account.chestionare.card-info')}}
			</p>
			<div class="chestionare">
				<div class="intrebare" v-for="(chestionar,index) in chestionareDisponibile" :key="chestionar.id">
						<template v-if="chestionar.tip == 1">
							<div>
								<p>
									<strong>
										{{index+1}}. {{chestionar['titlu']}}
									</strong>
								</p>
								<p>
									<b v-for="raspuns in chestionar.raspunsuri" :key="raspuns.id">
										<el-radio class="poll" v-model="raspunsuriChestionare[chestionar.id]" :label="raspuns.id" border>{{raspuns.value}}</el-radio>
									</b>
									<template v-if="chestionar.custom">
										<el-radio class="poll" v-model="raspunsuriChestionare[chestionar.id]" label="custom" border>{{$t('account.chestionare.custom-response')}}</el-radio>
										
										<b v-if="raspunsuriChestionare[chestionar.id] == 'custom'">
											<el-input class="poll poll-input" :placeholder="$t('account.chestionare.custom-response-input')" v-model="raspunsuriCustomChestionare[chestionar.id]" suffix-icon="el-icon-edit"></el-input>
										</b>
									</template>
								</p>
							</div>
						</template>
				</div>
			</div>
			<p>
				<el-button type="success" icon="el-icon-check" @click="salvareChestionar()">{{$t('account.chestionare.send-data')}}</el-button>
			</p>
		</el-card>
	</div>
	<div v-else-if="afisareSuccess">
		<el-alert :title="$t('account.chestionare.success-title')" type="success" :description="$t('account.chestionare.success-content')" show-icon></el-alert>
	</div>
</template>

<script>
	import {
		sync,
		get,
		call
	} from 'vuex-pathify';

	export default {
		data: () => ({
			afisareChestionare: false,
			afisareSuccess: false,
			ascundePentruZile: 7,
			raspunsuriChestionare: {},
			raspunsuriCustomChestionare: {},
			eroriChestionar: []
		}),
		computed: {
			chestionareDisponibile: get('account/chestionareDisponibile')
		},
		methods: {
			obtineChestionareDisponibile: call('account/GetChestionareDisponibile'),
			trimiteDateChestionar: call('account/SendDataChestionare'),

			funcAfisareChestionare() {
				if(localStorage.getItem("ascundeChestionarePentru") < 1) localStorage.setItem("ascundeChestionarePentru", 1);
				const timestampCurent = Math.floor(Date.now() / 1000);
				const ascundePentru = localStorage.getItem("ascundeChestionarePentru");
				if(timestampCurent > ascundePentru) return this.chestionareDisponibile ? true : false; else return false;
			},

			funcAscundePentruZile() {
				const timestampCurent = Math.floor(Date.now() / 1000);
				const ascundePentru = 86400 * this.ascundePentruZile;
				localStorage.setItem("ascundeChestionarePentru", timestampCurent+ascundePentru);
				this.afisareChestionare = false;
			},

			generareTextEroriChestionar() {
				return this.eroriChestionar.join(" ");
			},

			async salvareChestionar() {
				this.eroriChestionar = [];

				if(Object.keys(this.raspunsuriChestionare).length < 1) {
					this.eroriChestionar.push(this.$t('account.chestionare.no-responses'));
					return false;
				}

				this.chestionareDisponibile.forEach((chestionar,index) => {
					if(!Object.keys(this.raspunsuriChestionare).includes(chestionar.id.toString())) {
						this.eroriChestionar.push(this.$t('account.chestionare.no-reponse').replace("%q%",(index+1)));
					} else {
						if(this.raspunsuriChestionare[chestionar.id] == "custom") {
							if(!Object.keys(this.raspunsuriCustomChestionare).includes(chestionar.id.toString())) {
								this.eroriChestionar.push(this.$t('account.chestionare.no-reponse').replace("%q%",(index+1)));
							} else {
								if(this.raspunsuriCustomChestionare[chestionar.id].length < 1) {
									this.eroriChestionar.push(this.$t('account.chestionare.no-reponse').replace("%q%",(index+1)));
								}
							}
						}
					}
				});

				if(this.eroriChestionar.length) return false;

				try {
					await this.trimiteDateChestionar({chestionare:this.raspunsuriChestionare,customChestionare:this.raspunsuriCustomChestionare});
				} catch (err) {
					this.$error(err);
					this.$reportToSentry(e, {
						extra: {
							fn: 'GetChestionareDisponibile'
						}
					});
					return false;
				}

				this.afisareChestionare = false;
				this.afisareSuccess = true;
				return true;
			}
		},
		async mounted() {
			try {
				await this.obtineChestionareDisponibile();
				this.afisareChestionare = this.funcAfisareChestionare();
			} catch (err) {
				this.$error(err);
				this.$reportToSentry(e, {
					extra: {
						fn: 'GetChestionareDisponibile'
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.el-radio.is-bordered.is-checked {
		border-color: #5daf34!important;
	}

	.questionnaires {
		padding: 20px 20px 0px 20px;
	}

	.poll {
		margin-right: 3px;
		margin-left: 3px;
	}

	.poll-input {
		margin-top: 7px;
		margin-bottom: 7px;
	}

	.poll-hide {
		display:none;
	}

	.ascundeChestionare {
		float:right;
	}

	.container {
		display: flex;
		flex-direction: row;
		color: #606266;

		.card {
			width:100%;
		}

		justify-content: start;
		align-items: center;

		.chestionare {
			font-size: 14px;

			.intrebare {
				display: flex;
				flex-direction: row;
				justify-content: start;
				align-content: center;

				&:not(:last-child) {
					padding-bottom: 5px;
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		.container {
			display: flex;
			flex-direction: column;

			justify-content: center;
			align-content: center;

			p {
				font-size: 14px;
			}

			.chestionare {
				font-size: 13px;

				.step:not(:last-child) {
					padding-bottom: 10px;
				}
			}
		}
	}

	.cel-link:hover {
		color: rgb(255, 103, 35);
	}

	.color-cel {
		color: #ff601d;
	}
</style>